import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import contact from '../../assets/images/min.jpg'

const ContactForm = () => {
    return (
        <section id="contact" className="contact-area pt-70 pb-70">
            <div className="container ">
               

                <div className="row align-items-center">
                    

                    <div className="col-lg-6 col-md-12">
                    <div className="about-content">
                    <div className="content">
                    
                    <h2>Contact Us</h2> 
                       
                    
                    </div>
                    </div>
                        <div className="contact-form">
                       
                            <form id="contactForm">
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" name="name" className="form-control" required placeholder="Your name" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="email" name="email" className="form-control" required placeholder="Your email address" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input type="text" name="phone_number" className="form-control" required placeholder="Your phone number" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea name="message" className="form-control" cols="30" rows="6" required placeholder="Write your message..." />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="default-btn">
                                            
                                            Send Message <span></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image">
                            <img src={contact} alt="contact" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm