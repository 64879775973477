import React from 'react'

const ContactInfo = () => {
    return (
        <div id="contact" className="contact-info-area pt-70 pb-60">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <h3>Our Locations</h3>
                            <p className='smalltext'>114 New Cavendish Street, London, W1W 6XT</p>
                            <p className='smalltext'>260 Pentonville Road, London, N1 9JY</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <h3>Contact</h3>
                            <p className='smalltext'>Tel: <a href="tel:+440207 099 6690">0207 099 6690</a></p>
                            <p className='smalltext'>Email: <a href="mailto:info@minsony.com">info@minsony.com</a></p>
                            <p></p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <h3>Opening Hours</h3>
                            <p className='smalltext'>Monday to Saturday: 10am - 8pm</p>
                            <p className='smalltext'>Sunday: 10am to 7pm </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo